<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        xl="4"
        offset-xl="4"
        class="mt-sm-3"
      >
        <b-card
          :img-src="require('@/assets/images/customers/contacts/cp-pixel.png')"
          :img-alt="organization.address.companyName"
          img-top
          class="card-profile mb-50"
        >
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar
                v-if="false"
                size="114"
                variant="light-primary"
                :src="null"
              />
              <b-avatar
                v-else
                size="114"
                variant="light-primary"
              >
                <feather-icon
                  icon="BriefcaseIcon"
                  size="60"
                />
              </b-avatar>
            </div>
          </div>

          <rc-overlay
            :show="loading"
            variant="white"
            rounded
          >
            <div class="text-center">
              <h3>{{ organization.shortName }}</h3>
              <h6 class="font-weight-bold">
                <span v-if="organization.address.companyName !== organization.shortName">
                  {{ organization.address.companyName }}
                </span>
              </h6>
              <h6 class="text-muted">
                <span v-if="organization.address.addressLine1">
                  {{ organization.address.addressLine1 }},
                </span>
                {{ organization.address.zipCode }}
                {{ organization.address.city }}
              </h6>
              <b-badge
                v-if="organization.address.taxIdentifier"
                class="profile-badge mt-0"
                variant="light-primary"
              >
                NIP: {{ organization.address.taxIdentifier }}
              </b-badge>
            </div>

            <hr class="my-1">

            <!-- follower projects rank -->
            <b-row>
              <b-col class="text-center mt-1 ">
                <a
                  v-if="organization.phone"
                  :href="`tel:${organization.phone.replace(/ /g, '')}`"
                  class="text-center"
                >
                  <feather-icon
                    icon="PhoneIcon"
                    size="32"
                  />
                  <div class="mb-0 mt-1 font-weight-bold text-nowrap">
                    {{ organization.phone }}
                  </div>
                </a>
              </b-col>

              <b-col class="text-center mt-1 ">
                <a
                  v-if="organization.email"
                  :href="`mailto:${organization.email}`"
                  target="_blank"
                  class="text-center"
                >
                  <feather-icon
                    icon="MailIcon"
                    size="32"
                  />
                  <div class="mb-0 mt-1 font-weight-bold">
                    {{ organization.email }}
                  </div>
                </a>
              </b-col>
            </b-row>
            <!--/ follower projects rank -->
          </rc-overlay>
        </b-card>

        <div class="text-center font-weight-bold font-small-2 text-muted">
          <span>Powered by </span>
          <b-link
            href="https://totedi.com"
            target="_blank"
            class="text-muted"
          >Totedi</b-link>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import profileSetup from '@/views/profile/main/profileSetup'
import RcOverlay from '@/layouts/components/rc/RcOverlay'

export default {
  components: { RcOverlay },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup: profileSetup,
}
</script>
