import { ref } from '@vue/composition-api'
import { parseRequestError } from '@/helpers/helpers'
import profile from '@/http/requests/profile'

export default (props, { root }) => {
  const route = root.$route
  const { profileId } = route.params

  const organization = ref({
    id: 1,
    address: {
      companyName: 'Ładuję...', addressLine1: 'Wizytówkę firmy.', zipCode: '', city: '',
    },
    billingAddress: {
      companyName: 'Nazwa Firmy', addressLine1: 'Ulica i adres', zipCode: '00-000', city: 'Miasto',
    },
    shortName: 'Nazwa Firmy',
    email: 'adres@example.com',
    phone: '+00 000 000 000',
  })
  const loading = ref(true)

  profile.getOrganization(profileId)
    .then(response => {
      organization.value = response.data
    })
    .catch(err => parseRequestError(err))
    .finally(() => { loading.value = false })

  return {
    loading,
    organization,
  }
}
